import React from "react"
import styled from "@emotion/styled"

const Wrapper = styled.section`
  background: #efefef;
  position: relative;
  svg {
    width: 100%;
    position: absolute;
    left: 0;
    fill: #fafafa;
    top: -1px;
  }
`
const Container = styled.section`
  padding: 3rem 1.5rem;
  margin: 0 auto;
  max-width: 130rem;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  @media only screen and (max-width: 40em) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`

const Details = styled.div`
  padding: 7rem 0;
`

const Secondary = styled.h4`
  font-size: 6rem;
  font-weight: 700;
  color: #505050;
  line-height: 1.1;
  padding: 1rem 0;
  @media only screen and (max-width: 52em) {
    font-size: 5rem;
  }
  @media only screen and (max-width: 32.5em) {
    font-size: 4rem;
  }
`

const Body = styled.p`
  padding: 1rem 0;
  font-weight: 400;
  color: #505050;
`

const CtaWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0;
  div {
    :not(:last-child) {
      margin-right: 3rem;
    }
  }
  @media only screen and (max-width: 40em) {
    justify-content: center;
  }
`

const Picture = styled.picture`
  margin: 0 auto;
  width: 100%;
  max-width: 450px;
  z-index: 3;
`

const Ready = ({ svg, children }) => {
  return (
    <Wrapper>
      {svg && (
        <svg viewBox="0 0 1440 43">
          <path d="M1440 22.21v-98.79H0v98.79C120 8.35 240 1.42 360 1.42s240 6.93 360 20.79c88.328 8.794 154.574 14.333 198.738 16.618A3120.562 3120.562 0 001080 43c120 0 240-6.93 360-20.79z" />
        </svg>
      )}
      <Container>
        <Content>
          <Details>
            <Secondary>Ready to try ReCollect?</Secondary>
            <Body>Upgrade your recycling game and get your time back</Body>
            <CtaWrapper>{children}</CtaWrapper>
          </Details>
          <Picture>
            <source type="image/webp" srcSet="/img/uploads/truck-recollect-450px.webp" />
            <source type="image/png" srcSet="/img/uploads/truck-recollect-450px.png" />
            <img
              src="/img/uploads/truck-recollect-450px.png"
              alt="ReCollect Truck"
              loading="lazy"
              width="100%"
              height="100%"
            />
          </Picture>
        </Content>
      </Container>
    </Wrapper>
  )
}

export default Ready
