import React, { useState, useEffect, useContext } from "react"
import styled from "@emotion/styled"

import { LocationContext } from "../contexts/LocationContext"
import Card from "./Card"
import Phone from "../../static/img/svg/phone.svg"
import Pin from "../../static/img/svg/pin.svg"

const Container = styled.section`
  padding: 3rem 1.5rem;
`

const Wrapper = styled.div`
  padding: 3rem 0;
`

const Primary = styled.h2`
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #8cc949;
  background: linear-gradient(to right, #8cc949, #78B526);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`

const Subtitle = styled.h3`
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  padding: 1.5rem 10rem;
  @media only screen and (max-width: 52em) {
    padding: 1.5rem 0;
  }
`

const Secondary = styled.section`
  display: grid;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 21, 64, 0.25);
  transition: all 0.2s;
  max-width: 100rem;
  margin: 1rem auto;
  overflow: hidden;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, max-content);
  @media only screen and (max-width: 40em) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, max-content) 250px;
  }
`

const Image = styled.a`
  height: 100%;
  grid-column: 1 / 2;
  grid-row: 1 / -1;
  background: ${props => `url(${props.image}) center center/cover no-repeat`};
  color: #efefef;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  span {
    opacity: 0;
    z-index: 2;
    transition: all 0.3s;
  }
  &::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(51, 51, 51, 0.6);
    opacity: 0;
    transition: all 0.3s;
  }
  &:hover {
    span,
    &::after {
      opacity: 1;
    }
  }
`

const Content = styled.div`
  padding: 3rem;
  z-index: 2;
  background: #fafafa;
`

const Heading = styled.span`
  padding: 1rem 0;
  font-size: 2.5rem;
  color: #505050;
`

const Body = styled.p`
  padding: 1rem 0;
  display: flex;
  align-items: center;
`

const ExternalLink = styled.a`
  color: #404040;
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    color: #8cc949;
  }
`

const CardWrapper = styled.div`
  max-width: 100rem;
  margin: 0 auto;
  .card {
    padding: 0;
  }
`

const Map = ({ short, form, data: { title, subtitle, data } }) => {
  const svgStyles = { fill: "#505050", width: "2.5rem", marginRight: "1rem" }
  const { loc } = useContext(LocationContext)
  const [main, setMain] = useState({})
  const [addition, setAdditional] = useState([])

  useEffect(() => {
    if (data.length > 1 && loc.area) {
      let selectedMain = false, tentative
      let cityArr = []

      data.forEach(city => {
        let processedName = city.name.toLowerCase().replace(/\s/g, '')
        let matchesCity = processedName.includes(loc.city.toLowerCase())
        let matchesArea = processedName.includes(loc.area.toLowerCase())
        
        // If the current (depot) iteration is a city equivalent of the user's current location then set this as the main (top-most depot address to show)
        if (matchesCity) {
          setMain(city)
          selectedMain = true;
        }
        /* If the current (depot) iteration is an area equivalent of the user's current location then it is possible that this is the main if the loop does not 
        find a city equivalence on a later iteration hence it is the tentative main */
        else if (matchesArea) {
          tentative = city;
        } 
        /* Otherwise, if the current (depot) iteration is neither a city or an area equivalent of the the user's current location then set as part of additional
        (depot addresses below the main) */
        else {
          cityArr.push(city);
        }
      })

      setAdditional(cityArr)

      // If there exists a tentative main address...
      if (tentative) {
        // And a main has not been set, then set the tentative main as the official main to be placed on top
        if (!selectedMain) {
          setMain(tentative)
        }
        // Otherwise, if there is already a set main, then set this tentative main address below the official main alongside the additional addresses
        else {
          setAdditional(prevState => [tentative, ...prevState])
        }
      }       
    } else {
      setMain(data[0])
    }
  }, [loc])
  return (
    <Container>
      <Primary>{title}</Primary>
      <Subtitle>{subtitle}</Subtitle>
      <Wrapper>
        <CardWrapper>
          <Card>{form}</Card>
        </CardWrapper>
        <Secondary>
          <Image
            image={main.image ? `/img/${main.image.relativePath}` : null}
            href={main.url}
          >
            <span>Open in maps</span>
          </Image>
          <Content>
            <Heading>{main.name}</Heading>
            <Body>
              <Pin style={svgStyles} />
              {main.address}
            </Body>
            <Body>
              <Phone style={svgStyles} />
              <ExternalLink href={`tel:${main.phone}`}>
                {main.phone}
              </ExternalLink>
            </Body>
          </Content>
        </Secondary>
        {!short &&         
            addition &&
              addition.map(
                ({ name, address, email, phone, image, url }, count) => (                 
                    <Secondary>
                    <Image
                      image={image ? `/img/${image.relativePath}` : null}
                      href={url}
                    >
                      <span>Open in maps</span>
                    </Image>
                    <Content>
                      <Heading>{name}</Heading>
                      <Body>
                        <Pin style={svgStyles} />
                        {address}
                      </Body>
                      <Body>
                        <Phone style={svgStyles} />
                        <ExternalLink href={`tel:${phone}`}>
                          {phone}
                        </ExternalLink>
                      </Body>
                    </Content>
                    </Secondary>
                )          
              )
        }
      </Wrapper>
    </Container>
  )
}

export default Map
